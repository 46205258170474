
export const scale = [
  {
    col1: "<h1>Display x-large</h1><span class='caption'>default website h1</span>",
    col2: "<p>60px</p>",
    col3: "<p>Raleway Bold<br/> 0 character, 72 line, 0 paragraph</p>",
  },
  {
    col1: "<h2>Display large</h2><span class='caption'>default website h2</span>",
    col2: "<p>48px</p>",
    col3: "<p>Raleway Semibold <br/>0 character, 56 line, 0 paragraph</p>",
  },
  {
    col1: "<h3 class='display-m'>Display medium</h3><span class='caption'>default website h3</span>",
    col2: "<p>28px</p>",
    col3: "<p>Raleway Semibold<br/> 0 character, 60 line, 0 paragraph</p>",
  },
  {
    col1: "<p lass='display-sm'>Display small</p><span class='caption'>default website body</span>",
    col2: "<p>20px</p>",
    col3: "<p>Raleway Medium<br/> 0 character, 28 line, 1 paragraph</p>",
  },
  {
    col1: "<h1 class='page-heading'>PageHeading</h1><span class='caption'>default product h1</span>",
    col2: "<p>24px</p>",
    col3: "<p>Open Sans Semibold<br/> 0 character, 34 line, 0 paragraph</p>",
  },
  {
    col1: "<h2 class='heading'>Heading</h2><span class='caption'>default product h2</span>",
    col2: "<p>20px</p>",
    col3: "<p>Open Sans Semibold<br/> 0 character, 28 line, 0 paragraph</p>",
  },
  {
    col1: "<h3 class='section-heading'>Section Heading</h3><span class='caption'>default product h3</span>",
    col2: "<p>18px</p>",
    col3: "<p>Open Sans Semibold<br/> 0 character, 26 line, 0 paragraph</p>",
  },
  {
    col1: "<h4 class='subheading'>Subheading</h4><span class='caption'>default product h4</span>",
    col2: "<p>14px</p>",
    col3: "<p>Open Sans Semibold<br/> 0 character, 20 line, 0 paragraph</p>",
  },
  {
    col1: "<p class='product-body'>Product body</p><span class='caption'>default product body</span>",
    col2: "<p>14px</p>",
    col3:
      "<p>Open Sans Regular <br/> -0.18 character, 20 line, 1 paragraph</p>",
  },
  {
    col1: "<p class='caption'>Caption</p>",
    col2: "<p>12px</p>",
    col3: "<p>Open Sans Regular <br/>0 character, 20 line, 1 paragraph</p>",
  },
  {
    col1: "<p class='label'>Label</p>",
    col2: "<p>10px</p>",
    col3: "<p>Open Sans Regular<br/> 0 character, 26 line, 1 paragraph</p>",
  },
];

export const classes = [
  {
    title: "Classes",
    anchor: "classes",
    content: [
      {
        html:
          "<h1 class='display-xl'>Keep people moving</h1>\n<h2 class='display-l'>Keep people moving</h2>\n<h3 class='display-m'>Keep people moving</h3>\n<p class='display-sm'>Keep people moving</p>",
        description:
          "<h3>Display</h3>When you need a heading to stand out, consider using a display heading; a larger, slightly more opinionated heading style.",
      },
      {
        html: "<h1 class='page-heading'>Keep people moving</h1>",
        description:
          "<h3>Page Heading</h3>Page Heading is used in the title of a screen.",
      },
      {
        html: "<h2 class='heading'>Keep people moving</h2>",
        description:
          "<h3>Heading</h3>Heading should always be used for titles of top-level sections of a screen. If the sections of a screen are represented by cards, each card's title should use the Heading style",
      },
      {
        html: "<h3 class='section-heading'>Keep people moving</h3>",
        description:
          "<h3>Section Heading</h3> If a top-level section of a screen has subsections, use the Subheading style for titling those subsections. ",
      },
      {
        html: "<p class='subheading'>Keep people moving</p>",
        description:
          "<h3>Subheading</h3>If a top-level section of a screen has subsections, use the Subheading style for titling those subsections. ",
      },
      {
        html: "<p class='font-14'>Keep people moving</p>",
        description: "<h3>Body</h3>Body is the default level for content.",
        noSnippet: true,
      },
      {
        html: "<p class='caption'>Keep people moving</p>",
        description:
          "<h3>Caption</h3> Caption is for providing details in places where content is compact and space is tight, like when it comes to data visualisation.",
      },
      {
        html: "<p class='label'>Keep people moving</p>",
        description:
          "<h3>Label</h3> Label is the smallest font class of our system; commonly used to display axis in charts and key legends on maps. ",
      },
    ],
  },
];
