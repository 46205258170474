<template>
  <div class="left-menu">
    <router-link to="/" exact><img src="../assets/img/logo-designlab.svg" alt="logo" width="230" /></router-link>

    <input type="text" v-model="search" placeholder="Search" class="search full" />

    <ul>
      <li
        v-for="(item, index) in filteredList"
        :key="item.title"
        :class="{ 'main-menu': item.main }"
      >
        <a v-if="item.main">{{ item.name}}</a>

        <router-link v-if="!item.subMenu && !item.main" :to="item.link" :exact="item.exact">{{
          item.name
        }}</router-link>

        <div v-if="item.subMenu" @click="toggleMenu(index)" >
          {{ item.name }}
          <span class="chevron" :class="{ open: item.visible }"></span>
        </div>

        <ul v-show="item.subMenu && item.visible">
          <li v-for="sub in item.subMenu" :key="sub.name">
            <router-link :to="sub.link">{{ sub.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { leftMenu } from "../assets/data/left-menu.js";

export default {
  name: "LeftMenu",
  data: () => {
    return {
      leftMenu: leftMenu,
      isActive: false,
      search: "",
    };
  },
  computed: {
    filteredList() {
      let filteredArray = [];

      if (!this.search) {
        return this.leftMenu;
      } else {
        this.leftMenu.map((v) => {
          if (this.matches(v)) {
            filteredArray.push(v);
          }
          if (v.subMenu) {
            v.subMenu.map((x) => {
              if (this.matches(x)) {
                filteredArray.push(x);
              }
            });
          }
        });
        return filteredArray;
      }
    },
  },
  methods: {
    toggleMenu(i) {
      this.leftMenu[i].visible
        ? (this.leftMenu[i].visible = false)
        : this.$set(this.leftMenu[i], "visible", true);
    },
    matches(obj) {
      const term = this.search.toLowerCase();
      return obj.name.toLowerCase().includes(term);
    },
  },
};
</script>

