<template>
  <div>
    <div class="header-curve">
      <h1>Design</h1>
      <p class="subtitle">
        Use Intelematics' end-to-end design language to create simple,<br />
        intuitive and beautiful experiences
      </p>
    </div>

    <section class="welcome">
      <h2 class="display-m"><img src="../assets/img/two-dots.svg" alt="" role="presentation" /> Introduction to our brand</h2>

      <div class="box-container">
        <div class="box">
          <h3>What do we do?</h3>
          <p>
            At Intelematics, we want to make life on the roads and in the home
            better for the users of our products by providing them with engaging
            and highly valuable connected services.
          </p>
          <!-- <button href="" class="button--text">KNOW More</button> -->
        </div>

        <div class="box">
          <h3>Brand Overview</h3>
          <p>
            The Intelematics visual identity system consists of a suite of
            elements designed to provide you with the tools to create
            communications that correctly reflect the Intelematics brand.
          </p>
          <a href="/zip/INT0006_Intelematics Brand Guidelines_V2.pdf" target="_blank" class="dl">Download style guidelines (PDF)</a>
        </div>
      </div>
    </section>

    <section>
      <h2 class="display-m">Ready to build something?</h2>
      <p class="subtitle">
        Use components as building blocks as you develop new products and
        features.
      </p>

      <div class="box-container">  
        <div class="box blue-bg disabled">
          <h3><svg><use xlink:href="~@/assets/img/svg-defs.svg#web" fill="#1C2F5E"></use></svg> Web platform or CMS</h3>
          <p>Development rules for our web apps and CMS.</p>
          <button class="button--text"><a href="https://github.com/intelematics/designlab-resources">Coming soon</a></button>
        </div>
      
        <div class="box blue-bg disabled">
          <h3><svg><use xlink:href="~@/assets/img/svg-defs.svg#ios" fill="#1C2F5E"></use></svg> IOS</h3>
          <p>Development rules for our IOS apps.</p>
          <button href="" class="button--text">Coming soon</button>
        </div>

        <div class="box blue-bg disabled">
          <h3><svg><use xlink:href="~@/assets/img/svg-defs.svg#android" fill="#1C2F5E"></use></svg> Android</h3>
          <p>Development rules for our Android apps.</p>
          <button href="" class="button--text">Coming soon</button>
        </div>
      </div>
    </section>

    <router-view>
      <Buttons />
    </router-view>
  </div>
</template>

<script>
import Buttons from "./web/Buttons";

export default {
  name: "Welcome",
  components: {
    Buttons,
  },
};
</script>
