<template>
  <div>
    <section v-for="(item, i) in page" :key="item.title">
      <h2 :id="item.anchor">{{ item.title }}</h2>
      <p v-if="item.description" v-html="item.description"></p>

      <div class="col" v-for="(col, j) in item.content" :key="col.name">
        <div
          class="description"
          v-html="col.description"
          v-if="col.description"
        ></div>

        <div class="snippet-container" v-bind:class="col.className">
          <div v-html="col.html"></div>
          <svg
            class="icn-snippet"
            :class="{ active: col.visible, none: col.noSnippet }"
            @click="toggleSnippet(i, j)"
          >
            <use xlink:href="~@/assets/img/svg-defs.svg#snippet"></use>
          </svg>

          <div class="snippet" v-show="!col.noSnippet && col.visible">
            HTML
            <vue-code-highlight
              language="html"
              :class="{ none: col.noSnippet || !col.visible }"
              >{{ col.showCode ? col.showCode : col.html }}
            </vue-code-highlight>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "vue-code-highlight/themes/prism.css";
import { component as VueCodeHighlight } from "vue-code-highlight";

export default {
  name: "DefaultPage",
  components: {
    VueCodeHighlight,
  },
  props: {
    page: {
      type: Array,
    },
  },
  methods: {
    toggleSnippet(i, j) {
      this.page[i].content[j].visible
        ? (this.page[i].content[j].visible = false)
        : this.$set(this.page[i].content[j], "visible", true);
    },
  },
};
</script>