<template>
  <section class="cta" id="download">
    <div>
      <h2 class="display-m">{{title}}</h2>
      <p v-if="description">{{description}}</p>
    </div>
    <div>
      <button class="button--large button--outlined" @click="downloadZip()">{{buttonTitle}}</button>
    </div>
  </section>
</template>

<script>
export default {
  name: "DownloadCTA",
  props: {
    title: String,
    description: String,
    buttonTitle: String,
    link: String,
  },
  data () {
    return {
    publicPath: process.env.BASE_URL
    }
  },
  methods : {
    downloadZip: function() {
      window.open(this.link);
    }
  }
};
</script>