import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)
Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  routes: [
    { path: '/', component: () => import('./components/Welcome.vue'), exact: true },
    { path: '/foundations', component: () => import('./components/foundations/Foundations.vue')  },
    { path: '/foundations/logo', component: () => import('./components/foundations/Logo.vue')  },
    { path: '/foundations/colors', component: () => import('./components/foundations/Colors.vue')  },
    { path: '/foundations/typography', component: () => import('./components/foundations/Typography.vue')  },
    { path: '/foundations/iconography', component: () => import('./components/foundations/Iconography.vue')  },
    { path: '/foundations/graphic-treatments', component: () => import('./components/foundations/GraphicTreatments.vue')  },
    { path: '/foundations/photography', component: () => import('./components/foundations/Photography.vue')  },
    { path: '/foundations/data-visualisation', component: () => import('./components/foundations/DataVisualisation.vue')  },
    { path: '/foundations/tone-of-voice', component: () => import('./components/foundations/ToneOfVoice.vue')  },
    { path: '/foundations/tone-of-voice', component: () => import('./components/foundations/ToneOfVoice.vue')  },
    { path: '/resources/case-studies', component: () => import('./components/resources/CaseStudies.vue')  },
    { path: '/resources/white-papers', component: () => import('./components/resources/WhitePapers.vue')  },
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    if (!to.hash.includes('#')) {    
      Vue.prototype.$scrollTop(to)
    }
  },
})

Vue.use(VueGtag, {
  config: { id: "G-4LFYZHLHJ9" }
}, router);

Vue.prototype.$scrollTop = function (to) {
  if (!to.hash.includes('#')) { 
    var element = document.getElementById("content");
    element.scrollTo(0, 0);
  }
}


new Vue({
  render: h => h(App),
  router
}).$mount('#app')

