<template>
  <div class="with-anchors">
    <h1>Buttons</h1>
    <p>
      Buttons can be used to show the user's choice of options for actions and
      assign these to a clear hierarchy. The target page to be accessed or the
      action to be triggered is shown on the buttons by means of a label in Open
      Sans Regular or else by an icon, or by a combination of the two. All
      buttons provide visual feedback when clicked or tapped in the form of a
      response effect.
    </p>

    <AnchorMenu :anchors="buttons" github="https://github.com/intelematics/designlab-resources/blob/main/sass/buttons.scss"/>

    <DefaultPage :page="buttons" />
  </div>
</template>

<script>
import { buttons } from "../../assets/data/buttons";
import AnchorMenu from "../common/AnchorMenu";
import DefaultPage from "../common/DefaultPage";

export default {
  name: "Buttons",
  components: {
    AnchorMenu,
    DefaultPage
  },

  data: () => {
    return {
      buttons: buttons,
    };
  },
  
};
</script>

