<template>
  <div class="main">
    <LeftMenu />

    <div class="content" id="content">
      <router-view>
        <Foundations />
        <Welcome />
      </router-view>
    </div>
  </div>
</template>

<script>
import LeftMenu from './LeftMenu'
import Foundations from './foundations/Foundations'
import Welcome from './Welcome.vue'

export default {
  name: 'Home',
  components: {
    LeftMenu,
    Foundations,
    Welcome,
  },
}
</script>