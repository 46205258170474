export const buttons = [
  {
    title: "Types",
    anchor: "types",
    content: [
      {
        html: "<button>Contained</button>",
        description:
          "<h3>Contained</h3>For the principle call to action on the website pages.",
      },
      {
        html: "<button class='button--outlined'>Outlined</button>",
        description:
          "<h3>Outlined</h3>For the secondary call to action on the website pages",
      },
      {
        html: "<button class='button--subtle'>Subtle</button>",
        description:
          "<h3>Subtle</h3>For the principle call to action on the products interface.",
      },
      {
        html: "<button class='button--text'>Text</button>",
        description:
          "<h3>Text</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },

      {
        html: "<button class='button--reversed'>Reversed</button>\n<button class='button--reversed-outlined'>Reversed Outlined</button>",
        className: 'reversed',
        description: "<h3>Reversed</h3>",
      },
      { 
        html: "<button class='button--with-icon'>With icon</button>",
        description: "<h3>With icon</h3>",
      },
      {
        html: "<div class='question-mark'></div>",
        description:
          "<h3>Icon</h3>Icon used alone to identify an element or category.",
        noSnippet: true,
      },

    ],
  },
  {
    title: "Sizes",
    anchor: "sizes",
    description: "The size of buttons depends on the base font size used which can be adapted to the viewport width. <br/> <br/>Small button at a base font size of 12 px, e.g. in a smartphone app. Medium sized button at a base font size of 15 px, e.g. in a tablet app. Large button at a base font size of 18 px, e.g. on a website which is rendered in a wide viewport.",
    content: [
      { name: "buttonSmall", html: "<button>Small</button>\n<button class='button--medium'>Medium</button>\n<button class='button--large'>Large</button>"},
    ],
  },

  {
    title: "States",
    anchor: "states",
    content: [
      { html: "<button>Button</button> <button disabled>Button</button> <button class='hover'>Button</button>",
        description: "Active, disabled and hover states",
        noSnippet: true, 
      },
    ],
  },
];
