export const colorAnchors = [
    { title: "Intro", anchor: "intro" },
    { title: "Primary", anchor: "primary" },
    { title: "Secondary", anchor: "secondary" },
    { title: "Tertiary", anchor: "tertiary" },
    { title: "Gradient", anchor: "gradient" },
    { title: "Errors", anchor: "errors" },
];

export const dataVisualisationAnchors = [
    { title: "Intro", anchor: "intro" },
    { title: "How to start?", anchor: "start" },
    { title: "Type of charts", anchor: "types" },
    { title: "Grids & labels", anchor: "grids" },
    { title: "Other elements", anchor: "other" },
    { title: "Palette", anchor: "palette" },
    { title: "Tutorials", anchor: "tutorial" },
];

export const iconographyAnchors = [
    { title: "Value icons", anchor: "value" },
    { title: "Hero icons", anchor: "hero" },
    { title: "Functional", anchor: "functional" },
];

export const photoAnchors = [
    { title: "Styling", anchor: "styling" },
    { title: "Treatment", anchor: "treatment" },
    { title: "Download", anchor: "download" },
];

export const graphicAnchors = [
    { title: "Holding Devices", anchor: "holding" },
    { title: "Treatment", anchor: "treatment" },
];

export const logoAnchors = [
    { title: "Logo suite", anchor: "suite" },
    { title: "Space and size", anchor: "clear" },
    { title: "Download", anchor: "download" },
];

export const typoAnchors = [
    { title: "Intro", anchor: "intro" },
    { title: "Type", anchor: "type" },
    { title: "Download", anchor: "download" },
];

export const toneAnchors = [
    { title: "Intro", anchor: "intro" },
    { title: "Brand Persona", anchor: "brand" },
    { title: "VoiceMap", anchor: "voicemap" },
    { title: "Example", anchor: "example" },
];
  