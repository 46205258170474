<template>
  <div class="with-anchors">
    <h1 id="intro" class="display-l">Colour palette</h1>
    <p>The Intelematics colour palette has been selected to convey energy and innovation, in a contemporary and approachable way. In addition, our palette was carefully adjusted to ensure  high contrast ratio and meet accessibility criteria. 
      <!-- If you are developer, access to the <a href="https://github.com/intelematics/designlab-resources/blob/main/sass/colors.scss" target="#">SASS file here.</a> -->
      </p>

    <!-- primary -->
    <section id="primary">
      <h2 class="display-m">Primary</h2>
      <p>Dark Blue is Intelematics' primary brand colour. The best visual expression of the Intelematics brand will utilise a strong base of dark blue with pops of the secondary brand colours to add energy and interest. It may also be appropriate to utilise generous areas of white space to keep the colours fresh and let content breathe.</p>
      <div class="flex-container">
        <div class="col">
          <Color :name="'Navy'" :pantone="'534C'" :cmyk="'100, 89, 35, 26'" :rgb="'28, 47, 94'" :hex="'#1C2F5E;'" /><br/>
          <Color :name="'BEIGE'"  :cmyk="'2, 2, 5, 0'" :rgb="'246, 245, 238'" :hex="'#F6F5EE;'" />
        </div>
        <div class="col">
          <Color :name="'WHITE (W)'" :cmyk="'0, 0, 0, 0'" :rgb="'256, 256, 256'" :hex="'#FFFFFF'" />
        </div>
      </div>

      <h3>Primary variants</h3>
      <p>We use shades of our primary colour to generate a neutral colour palette in our Product UI.</p>
      
      <div class="flex-container">
        <div class="col">
          <Color :name="'N70'" :cmyk="'18, 13, 0, 50'" :rgb="'81, 94, 128'" :hex="'#515E80'" /><br/>
          <Color :name="'N40'" :cmyk="'11, 7, 0, 25'" :rgb="'164, 172, 191'" :hex="'#A4ACBF'" />
        </div>

        <div class="col">
          <Color :name="'N20'" :cmyk="'5, 4, 0, 13'" :rgb="'210, 213, 223'" :hex="'#D2D5DF'" /><br/>
          <Color :name="'N10'" :cmyk="'3, 2, 0, 7'" :rgb="'232, 234, 238'" :hex="'#E8EAEE'" />
        </div>
      </div>
    </section>

    <!-- secondary -->
    <section id="secondary">
      <h2 class="display-m">Secondary</h2>
      <p>To be used as an extension of the secondary palette when additional colours are needed e.g. data visualisation, secondary content pages and internal communications.</p>

      <div class="flex-container">
        <div class="col">
          <Color :name="'Cornflower'" :pantone="'284C'" :cmyk="'48,24,0,0'" :rgb="'119,174,255'" :hex="'#77AEFF'" /><br/>
          <Color :name="'Light Cornflower'" :pantone="'2707C'" :cmyk="'20,7,0,0'" :rgb="'196, 221, 255'" :hex="'#C5DDFF'" /><br/>
          <Color :name="'Dark Cornflower'" :pantone="'7683C'" :cmyk="'75,58,0,4'" :rgb="'83,109,174'" :hex="'#536DAE'" /><br/>
        </div>
        <div class="col">
          <Color :name="'Mint'" :pantone="'7471C'" :cmyk="'44,0,28,0'" :rgb="'131,222,202'" :hex="'#83E0CA'" /><br/>
          <Color :name="'Light Mint'" :pantone="'317C'" :cmyk="'27,0,15,0'" :rgb="'173,255,235'" :hex="'#ADFFEB'" /><br/>
          <Color :name="'Dark Mint'" :pantone="'7475C'" :cmyk="'77,33,48,8'" :rgb="'64,128,126'" :hex="'#3F817F'" />
        </div>
      </div>
    </section>

    <!-- tertiary -->
    <section id="tertiary">
      <h2 class="display-m">Tertiary</h2>
      <p>To be used as an extension of the secondary palette when additional colours are needed e.g. data visualisation, secondary content pages and internal communications.</p>
      <div class="flex-container">
        <div class="col">
          <Color :name="'Dodger blue'" :cmyk="'80,60,0,0'" :rgb="'225,15,0,0'" :hex="'#426FE1'" /><br/>
          <Color :name="'Light Dodger blue'" :cmyk="'25,15,0,0'" :rgb="'183,204,255'" :hex="'#B7CCFF'" /><br/>
          <Color :name="'Dark Dodger blue'" :cmyk="'84,69,0,0'" :rgb="'54,92,186'" :hex="'#365CBA'" /><br/><br/>

          <Color :name="'Tangerine'" :cmyk="'4,33,73,0'" :rgb="'242,178,92'" :hex="'#F2B25C'" /><br/>
          <Color :name="'Light Tangerine'" :cmyk="'0,12,27,0'" :rgb="'255,226,188'" :hex="'#FFE2BC'" /><br/>
          <Color :name="'Dark Tangerine'" :cmyk="'24,47,87,5'" :rgb="'188,135,64'" :hex="'#BC8740'" /><br/><br/>

          <Color :name="'Smoke'" :cmyk="'49,39,31,1'" :rgb="'138,143,155'" :hex="'#8A8F9B'" /><br/>
          <Color :name="'Light Smoke'" :cmyk="'0,0,0,15'" :rgb="'207,208,211'" :hex="'#CFD0D3'" /><br/>
          <Color :name="'Dark Smoke'" :cmyk="'0,0,0,85'" :rgb="'38,38,38'" :hex="'#262626'" /><br/>
        </div>

        <div class="col">
          <Color :name="'Ocean'" :cmyk="'69,13,11,0'" :rgb="'60,173,208'" :hex="'#3CADD0'" /><br/>
          <Color :name="'Light Ocean'" :cmyk="'35,8,0,3'" :rgb="'158,226,247'" :hex="'#9EE2F7'" /><br/>
          <Color :name="'Dark Ocean'" :cmyk="'83,41,31,4'" :rgb="'41,123,149'" :hex="'#297B95'" /><br/><br/>

          <Color :name="'Rose'" :cmyk="'0,88,21,0'" :rgb="'255,65,129'" :hex="'#FF4181'" /><br/>
          <Color :name="'Light Rose'" :cmyk="'0,36,2,0'" :rgb="'255,183,207'" :hex="'#FFb7CF'" /><br/>
          <Color :name="'Dark Rose'" :cmyk="'14,94,51,104'" :rgb="'209,51,104'" :hex="'#D13368'" /><br/><br/>

          <Color :name="'Orchid'" :cmyk="'42,64,0,0'" :rgb="'185,107,239'" :hex="'#b96bef'" /><br/>
          <Color :name="'Light Orchid'" :cmyk="'14,28,0,0'" :rgb="'227,186,255'" :hex="'#e3baff'" /><br/>
          <Color :name="'Dark Orchid'" :cmyk="'52,74,0,0'" :rgb="'155,87,201'" :hex="'#9b57c9'" /><br/>

        </div>
      </div>

    </section>

    <!-- gradient -->
    <section id="gradient">
      <h2 class="display-m">Gradient</h2>
      <p>Our gradient is created from mint and cornflower. Used to give a sense of movement and progress, it can be used either horizontally or vertically.
      <br/><br/>
      Colour percentages<br/>
      The percentages of colour used to create the gradient should always follow the positioning guides as seen on the right, and listed below, to ensure a consistent percentage of colour.<br/>
      <span style="color:#3F817F">• Mint 50%<br/>
      • Cornflower 50%</span>
      <br/><br/>
      Things to avoid:<br/>
      • Avoid applying the gradient to shapes that are too narrow and don't allow colours enough room to blend into one another.<br/>
      • Avoid placing graphic elements over parts of the gradient that will cause them to be illegible.</p>

      <div class="flex-container">
        <div class="col">
          <Color :name="'GRADIENT (GD)'" :cmyk="'44, 0, 28, 0 > 48, 24, 0, 0'" :rgb="'131, 224, 202'" :hex="'#83E0CA > #77AEFF'" :gradient="true"/><br/>
        </div>
      </div>
    </section>

    <!-- Usage -->
    <section id="errors">
      <h2 class="display-m">Errors, successes and alerts</h2>
      <p>Green and red are commonly associated with positive/negative outcome; and amber with alert messages. We combine the darkest and lightest tones to ensure legibilit. Reserve the brighest tone when need to stand out. </p>
      <div class="flex-container">
        <div class="col">
          <h3>Error</h3>
          <Color :name="'DARK RED (DR)'" :rgb="'191, 23, 34'" :hex="'#BF1722'" /><br/>
          <Color :name="'RED (R)'" :rgb="'240, 62, 62'" :hex="'#F03E3E'" /><br/>
          <Color :name="'LIGHT RED (LR)'" :rgb="'251, 234, 229'" :hex="'#FBEAE5'" />
        </div>
        <div class="col">
          <h3>Success</h3>
          <Color :name="'DARK GREEN (DG)'" :rgb="'22, 101, 104'" :hex="'#168368'" /><br/>
          <Color :name="'GREEN (G)'" :rgb="'37, 206, 163'" :hex="'#25CEA3 '" /><br/>
          <Color :name="'LIGHT GREEN (LG)'" :rgb="'223, 253, 236'" :hex="'#DFFDEC'" />
        </div>
      </div>
    </section>

    <!-- Alert-->
    <section id="alert">
      <h2 class="display-m">Alert</h2>	 
      <Color :name="'DARK YELLOW (DY)'" :rgb="'143, 98, 20'" :hex="'#8F6214'" /><br/>
      <Color :name="'YELLOW (Y)'" :rgb="'243, 207, 48'" :hex="'#F3CF30'" /><br/>
      <Color :name="'LIGHT YELLOW (LO)'" :rgb="'254, 244, 217'" :hex="'#FCF4D9'" />
    </section>

    <!-- Hover -->
    <section id="hover">
      <h2 class="display-m">Hover</h2>
      <p>Use navy blue whenever possible for hover states. </p>
      <Color :name="'Hover'" :pantone="'534C'" :cmyk="'100, 89, 35, 26'" :rgb="'28, 47, 94'" :hex="'#1C2F5E;'"  /><br/>
    </section>

      <!-- github="https://github.com/intelematics/designlab-resources/blob/main/sass/colors.scss" -->
    <AnchorMenu :anchors="colorMenu" />
  </div>
</template>

<script>
import Color from './colors/Color'
import AnchorMenu from '../common/AnchorMenu'
import { colorAnchors } from '../../assets/data/anchors'

export default {
  name: 'Colors',
  components: {
    Color,
    AnchorMenu
  },
  data: () => {
    return {
      colorMenu: colorAnchors,
    };
  },
}
</script>


