<template>
  <div class="with-anchors image-space">
    <h1 class="display-l">Typography</h1>
    <!-- github="https://github.com/intelematics/designlab-resources/blob/main/sass/typography.scss" -->
    <AnchorMenu :anchors="anchors" />

    <!-- Intro -->
    <section>
      <h1 class="text-dark-mint" id="intro">
        This is Graphik. <br />Our brand typeface.
      </h1>
      <p>
        Our brand font is Graphik, a simple and contemporary complementing, our
        wordmark. It is available in a variety of weights, but to ensure
        consistency we only use weights Light through to Semi Bold. It is
        important that our typography always feels light, refined and technical,
        never heavy and over bearing. Light, Regular, and Medium should be
        sufficient for most communications. However, there may be occasions
        where Semi Bold is needed as an additional level of hierarchy.
      </p>
      <br /><br />

      <h2 class="display-m">System font</h2>
      <p>
        We should always aim to use our brand font Graphik. However in
        circumstances where a platform does not allow the use of our brand font,
        such as Microsoft Word we always use the system font Arial. Note, Arial
        should never be used as an alternative to the brand font Graphik in
        public facing communications no matter how small e.g. a Facebook or
        Instagram post.
      </p>
    </section>

    <!-- Type treatment -->
    <section>
      <h2 id="type" class="display-m">Type treatment</h2>
      <p class="page-heading">Cursor treatment</p>
      <p>
        Used to provide a sense of structure the 'Cursor' is a flexible device
        that alters dependent on the content it is paired with. The 'Cursor' can
        appear in any colour within our palette and always paired with either
        the same colour headline, or headline in white as shown on the examples
        in the right.
      </p>
      <p><img src="../../assets/img/typo1.png" alt="" role="presentation" /></p>
      <br />

      <p class="page-heading">Highbeam treatment</p>
      <p>
        The 'Highbeam' is used as a secondary text treatment to highlight key
        headlines or pullout text on secondary pages. The device can be
        positioned either above or aligned with the text, this should be
        dictated by what is going to best visually balance the layout. The
        'Highbeam' device should always appear in colour and either match the
        text or the text may also be white as shown in the examples on the
        right.
      </p>
      <p><img src="../../assets/img/typo2.png" alt="" role="presentation" /></p>
      <br />

      <p class="page-heading">Coloured/Gradient Treatment</p>
      <p>
        When styling key headlines without the 'Cursor' or 'Highbeam'
        treatments, it is important that they have a sense of energy to them.
        This can be achieved by either using a colour or the gradient from our
        palette as shown in the examples on the right. Please note that text
        treatments should not be used in combination with one another to avoid
        overly complicated visual stying.
      </p>
      <p><img src="../../assets/img/typo3.png" alt="" role="presentation" /></p>
    </section>

    <!-- <DefaultPage :page="classes"/> -->

    <DownloadCTA
      title="Download Graphik font family"
      buttonTitle="Download .zip"
      description="Files available in .otf format"
      link="/zip/Intelematics-font-graphik.zip"
    />
  </div>
</template>

<script>
import AnchorMenu from "../common/AnchorMenu";
import DownloadCTA from "../common/DownloadCTA.vue";
// import DefaultPage from "../common/DefaultPage";
import { scale, classes } from "../../assets/data/typo";
import { typoAnchors } from "../../assets/data/anchors";

export default {
  name: "Typography",
  components: {
    AnchorMenu,
    // DefaultPage
    DownloadCTA,
  },
  data: () => {
    return {
      anchors: typoAnchors,
      scale: scale,
      classes: classes,
    };
  },
};
</script>


