<template>
  <div class="anchor-menu">
    CONTENTS
   <ul>
      <li v-for="item in anchors" :key="item.title">
        <a :href="'#' + item.anchor" v-smooth-scroll="{ duration: 1000, container: '#content', updateHistory: false }">{{item.title}}</a>
      </li>
      <li v-if="github"><a :href="github"><img src="../../assets/img/github.svg" width="25"/></a></li>
   </ul>
  </div>
</template>

<script>

export default {
  name: 'AnchorMenu',
  props: {
    anchors: {
      type: Array,
    },
    github: {
      type: String,
    }
  },
}
</script>