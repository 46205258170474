export const leftMenu = [
    { name: "Get started", main: true },
    { name: "Welcome", link: "/", exact: true },
    { name: "Foundations",  main: true },
    { name: "Logo", link: "/foundations/logo" },
    { name: "Colour palette", link: "/foundations/colors" },
    { name: "Typography", link: "/foundations/typography" },
    { name: "Iconography", link: "/foundations/iconography" },
    { name: "Graphic Treatments", link: "/foundations/graphic-treatments" },
    { name: "Photography", link: "/foundations/photography" },
    { name: "Tone of voice", link: "/foundations/tone-of-voice" },
    { name: "Resources",  main: true },
    { name: "Case Studies", link: "/resources/case-studies" },
    { name: "White Papers", link: "/resources/white-papers" },
    //{ name: "Data visualisation", link: "/foundations/data-visualisation" },
    // { name: "Development", main: true },
    // { name: "Web platform", link: "/web", subMenu: [
    //     { name: "Buttons", link: "/web/buttons" },
    // ]},
]