<template>
  <div>
    <h1>Foundations</h1>

    <router-view>
        <Colors />
        <Typography />
    </router-view>
  </div>
</template>

<script>
import Colors from './Colors'
import Typography from './Typography'

export default {
  name: 'Foundations',
  components: {
    Colors,
    Typography,
  }
}
</script>
