<template>
  <div class="color-container">
      <div class="left">
        <div class="color-square" 
             :style="!gradient ? 'background:' +  hex : null"
             :class="{'border': hex === '#FFFFFF', 'gradient': gradient}">
        </div>
      </div>

      <div class="left">
          <span class="subheading">{{name}}</span><br/>
          <span v-if="pantone">Pantone: {{pantone}}<br/></span>
          <span v-if="cmyk">CMYK: {{cmyk}}<br/></span>
          RGB: {{rgb}}<br/>
          Hex: {{hex}}
      </div>
  </div>
</template>

<script>
export default {
  name: 'Color',

  props: {
    pantone: String,
    name: String,
    cmyk: String,
    rgb: String,
    hex: String,
    gradient: Boolean,
  },
}
</script>